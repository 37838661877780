img {
  max-width: 100%;
  vertical-align: top;
  border: none;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}